import React, { useRef, useEffect, useContext, useState } from "react";
import useApi from "../../../hooks/apiHook";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/userContext";

import handleError from "../../../functions/handleError";
import Loading from "../../../components/Loading";

import DataTable from "../../../components/DataTable";
import WhiteBox from "../../../components/WhiteBox";
import TableButton from "../../../components/TableButton";

import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import handleSuccess from "../../../functions/handleSuccess";
import Moment from "moment";

function Request(props) {
    const LockerStatuses = {
        1: "Reserved",
        2: "Ready",
        3: "Expired",
        4: "Resolved",
    };
    const { user, token } = useContext(UserContext);
    const { getLockerRequests, deleteLockerRequest, loading } = useApi();

    //getAllRequests
    const [lockerRequests, setLockerRequests] = useState([]);

    const getLockerRequestDetails = async () => {
        await getLockerRequests(token)
            .then((response) => {
                setLockerRequests(response.requests);
                setFilteredRequest(response.requests);
            })
            .catch((err) => {
                if (err.message != "Requests not found") {
                    handleError(err.message);
                    setLockerRequests([]);
                } else {
                    setLockerRequests([]);
                }
            });
    };

    useEffect(() => {
        if (token) {
            getLockerRequestDetails();
        }
    }, [token]);

    //columns
    const columns = [
        {
            name: "Locker Location",
            selector: (row) => row.locker.location,
            sortable: true,
        },
        {
            name: "Locker Slot",
            selector: (row) =>
                row.slots.map((x, index) => (index ? ", " : "") + x.number),
            // selector: (row) => row.slots.map(x => x.number)
            sortable: true,
        },
        {
            name: "Type",
            width: "90px",
            selector: (row) =>
                `${row.type === "purchase" ? "Purchase" : "Rent"}`,
            sortable: true,
        },
        {
            name: "Status",
            width: "90px",
            selector: (row) => row.status,
            cell: (row) => {
                return LockerStatuses[row.status];
            },
            sortable: true,
        },
        {
            name: "Order ID",
            width: "180px",
            selector: (row) => row.orderId,
            sortable: true,
        },
        {
            name: "Customer Email",
            selector: (row) => row.customer.email,
            sortable: true,
        },
        {
            name: "Expiry Period",
            // width: "90px",
            selector: (row) =>
                Moment(`${row.validityPeriod}`).format("DD/MM/YYYY"),
            // selector: (row) => row.validityPeriod,
            sortable: true,
        },
        {
            name: "UAP",
            selector: (row) => row.uap,
            sortable: true,
        },
        {
            name: "Options",
            width: "190px",
            cell: (row, index, column, id) => {
                return (
                    <div className="flex flex-row">
                        <Link to={`/dashboard/requests/edit/${row._id}`}>
                            <TableButton>Edit</TableButton>
                        </Link>
                        <TableButton
                            onClick={() => {
                                deleteSelectedRequest(
                                    row._id,
                                    row.locker.location
                                );
                            }}
                        >
                            Delete
                        </TableButton>
                    </div>
                );
            },
        },
    ];

    //delete selected request
    const deleteSelectedRequest = (id, lockerLocation) => {
        Swal.fire({
            icon: "warning",
            title: `Are you sure you want to delete the following Request under ${lockerLocation}?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Loading",
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();
                        let response = await deleteLockerRequest(token, id)
                            .then((response) => {
                                handleSuccess(
                                    "Locker request deleted successfully"
                                );
                                getLockerRequestDetails();
                            })
                            .catch((err) => {
                                handleError(err);
                            });
                    },
                });
            }
        });
    };

    // search request
    const [searchData, setSearchData] = useState("");
    const [filteredRequest, setFilteredRequest] = useState([]);

    useEffect(() => {
        const resultOfRequestTable = lockerRequests.filter((request) => {
            const filteredArray = request.slots.filter((inner) =>
                inner.number.toLowerCase().match(searchData.toLowerCase())
            );
            // console.log(filteredArray.map(x => x.number).toString().toLowerCase().match(searchData.toLowerCase())) // === filterRequest
            return (
                request.locker.location
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                filteredArray
                    .map((x) => x.number)
                    .toString()
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                request.type.toLowerCase().match(searchData.toLowerCase()) ||
                request.orderId.toLowerCase().match(searchData.toLowerCase()) ||
                request.customer.email
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                Moment(request.validityPeriod)
                    .format("DD/MM/YYYY")
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                request.uap.toLowerCase().match(searchData.toLowerCase())
            );
        });
        setFilteredRequest(resultOfRequestTable);
    }, [searchData]);
    const handleSearchData = (e) => {
        setSearchData(e.target.value);
    };
    // console.log(searchData)
    // console.log(filteredRequest)

    return (
        <div className="flex flex-col">
            <div className="flex flex-row">
                <h2 className="text-4xl">Requests</h2>
            </div>
            <div className="flex flex-row mb-4 justify-end">
                <Link to="/dashboard/requests/new">
                    <button
                        type="button"
                        className="flex flex-row items-center ml-4 py-2 px-3 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                    >
                        Create Locker Request{" "}
                        <FaPlus size="1.25em" className="ml-2" />
                    </button>
                </Link>
            </div>
            <WhiteBox>
                {loading && <Loading />}
                {!loading && (
                    <DataTable
                        // searchFields={["locker.location", "lockerSlot", "type", "orderId", "customer.email", "expiryPeriod", "uap"]}
                        columns={columns}
                        data={filteredRequest}
                        subHeader
                        subHeaderComponent
                        value={searchData}
                        onChange={handleSearchData}
                    />
                )}
            </WhiteBox>
        </div>
    );
}

export default Request;
