import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMatch, useNavigate } from "react-router-dom";
import apiHook from "../../../hooks/apiHook";
import * as yup from "yup";

import Swal from "sweetalert2";
import { UserContext } from "../../../context/userContext";

import handleError from "../../../functions/handleError";
import handleSuccess from "../../../functions/handleSuccess";

import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import WhiteBox from "../../../components/WhiteBox";
import Select from "react-select";

const schema = yup
    .object({
        number: yup.string().required("Number is required"),
        // size: yup.string().required("Size is required"),
    })
    .required();

const options = [
    { value: "sm", label: "Small" },
    { value: "md", label: "Medium" },
    { value: "lg", label: "Large" },
];

function NewLockerSlot(props) {
    const { createLockerSlot, loading } = apiHook();
    const { token } = useContext(UserContext);
    const { params } = useMatch("/dashboard/lockers/slots/new/:id");
    const [selectSize, setSelectSize] = useState("");

    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        console.log(data);
        clearErrors();
        Swal.fire({
            title: "Loading",
            allowOutsideClick: false,
            didOpen: async () => {
                let id = params.id;
                Swal.showLoading();
                await createLockerSlot(token, {
                    locker: id,
                    size: selectSize,
                    number: data.number,
                    hexCode: data.hexCode,
                })
                    .then((response) => {
                        handleSuccess("Locker slot created successfully");
                        navigate(-1, {
                            replace: true,
                        });
                    })
                    .catch((err) => {
                        handleError(err.message);
                    });
            },
        });
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row mb-4 pb-4">
                <BackButton />
                <h2 className="text-4xl ml-2">Create New Locker Slot</h2>
            </div>
            <WhiteBox>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full flex flex-col"
                >
                    <h2 className="mb-4 font-semibold text-xl">
                        Create New Locker Slots Setup
                    </h2>
                    <div className="w-full flex">
                        <div className="mb-3 xl:w-full">
                            <label className="form-label inline-block mb-2 text-gray-800 text-md">
                                Size
                            </label>
                            <Select
                                name="size"
                                onChange={(data) => setSelectSize(data.value)}
                                options={options}
                            />
                        </div>
                    </div>
                    <FormInput
                        id="number"
                        name="Number"
                        register={register}
                        defaultValue=""
                        errors={errors}
                        disabled={loading}
                    />
                    <FormInput
                        id="hexCode"
                        name="Hex Code"
                        register={register}
                        defaultValue=""
                        errors={errors}
                        disabled={loading}
                    />
                    <div className="flex w-full mt-4">
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex flex-row items-center py-4 px-4 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </WhiteBox>
        </div>
    );
}

export default NewLockerSlot;
