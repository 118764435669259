import React, { useEffect, useContext, useState } from "react";
import BackButton from "../../../components/BackButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/apiHook";
import * as yup from "yup";
import Swal from "sweetalert2";
import { UserContext } from "../../../context/userContext";
import handleError from "../../../functions/handleError";
import handleSuccess from "../../../functions/handleSuccess";
import FormInput from "../../../components/FormInput";
import WhiteBox from "../../../components/WhiteBox";
import Select from "react-select";

const schema = yup
  .object({
    // lockerLocation: yup.string().required("Locker Location is required"),
    // lockerSlots: yup.string().required("Locker Slots is required"),
    // type: yup.string().required("Type is required"),
    orderId: yup.string().required("Order ID is required"),
    expiryDate: yup.string().required("Expiry Date is required"),
    name: yup.string().required("Customer Name is required"),
    email: yup
      .string()
      .required("Customer Email is required")
      .email("Invalid Email Format"),
    phone: yup
      .number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .required("A phone number is required"),
  })
  .required();

function NewRequest(props) {
  let navigate = useNavigate();
  const { token } = useContext(UserContext);
  const { getLockers, loading, getAvailableLockerSlotsByLocker, createRequest } =
    useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //get all lockers list
  const [lockers, setLockers] = useState([]);
  const [locker, setLocker] = useState("");
  const getLockerDetails = async () => {
    await getLockers(token)
      .then((response) => {
        setLockers(response.lockers);
      })
      .catch((err) => {
        if (err.message != "Lockers not found") {
          handleError(err.message);
          setLockers([]);
        } else {
          setLockers([]);
        }
      });
  };
  // console.log(locker)

  useEffect(() => {
    if (token) {
      getLockerDetails();
    }
  }, [token]);

  //get lockers slot list id
  //locker = params.id /id
  const [lockerSlots, setLockerSlots] = useState([]);
  const [lockerSlot, setLockerSlot] = useState("");
  const getDetails = async () => {
    await getAvailableLockerSlotsByLocker(token, locker)
      .then((response) => {
        let slots = response.slots;
        slots.sort((a, b) => a.number - b.number);
        setLockerSlots(slots);
      })
      .catch((err) => {
        if (err.message != "Slots not found") {
          handleError(err.message);
        } else {
          setLockerSlots([]);
        }
      });
  };
  useEffect(() => {
    if ((token, locker)) {
      getDetails();
    }
  }, [token, locker]);

  // console.log(lockerSlot)
  // const lockerSlotValue = lockerSlot.map(x => x.value)
  // console.log(lockerSlotValue)

  //handle submit new request
  const onSubmit = (data) => {
    console.log(data);
    if(lockerSlot.length == 0) {
      handleError("Please select a locker slot(s)");
      return;
    }
    Swal.fire({
      title: "Loading",
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        await createRequest(token, {
          locker: locker,
          slots: lockerSlot.map((x) => x.value),
          type: type,
          validityPeriod: data.expiryDate,
          orderId: data.orderId,
          customer: {
            name: data.name,
            email: data.email,
            phone: data.phone,
          },
        })
          .then((response) => {
            handleSuccess("Locker Request created successfully");
            navigate("/dashboard/requests", { replace: true });
          })
          .catch((err) => {
            handleError(err.message);
          });
      },
    });
  };

  //type options
  const [type, setType] = useState("");
  const typeOptions = [
    { value: "purchase", label: "Purchase" },
    { value: "rent", label: "Rent" },
  ];
  //date
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-4 pb-4">
        <BackButton />
        <h2 className="text-4xl ml-2">New Locker Request</h2>
      </div>
      <WhiteBox>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col px-5"
        >
          <div className="mb-4 w-full">
            <label
              htmlFor="lockerLocation"
              className="form-label inline-block mb-2 text-gray-800 text-md"
            >
              Locker Location
            </label>
            <Select
              name="lockerLocation"
              onChange={(data) => setLocker(data.value)}
              options={lockers.map((option) => ({
                value: option._id,
                label: option.location,
              }))}
              placeholder="Select Locker Location"
              // className="mb-2"
            />
          </div>
          {locker && (
            <div className="mb-4 w-full">
              <label
                htmlFor="lockerSlot"
                className="form-label inline-block mb-2 text-gray-800 text-md"
              >
                Locker Slots
              </label>
              <Select
                isMulti
                name="lockerSlot"
                options={lockerSlots.map((option) => ({
                  value: option._id,
                  label: `${option.number} - Size ${option.size.toUpperCase()}`,
                }))}
                className="basic-multi-select "
                classNamePrefix="select"
                placeholder="Select Locker Slots"
                onChange={(data) => {
                  setLockerSlot(data);
                }}
              />
            </div>
          )}
          <div className="mb-4 w-full">
            <label
              htmlFor="type"
              className="form-label inline-block mb-2 text-gray-800 text-md"
            >
              Type
            </label>
            <Select
              name="type"
              onChange={(data) => setType(data.value)}
              options={typeOptions}
              placeholder="Select Type"
              // className="mb-2"
            />
          </div>

          <FormInput
            id="expiryDate"
            name="Expiry Date"
            type="date"
            register={register}
            errors={errors}
            disabled={loading}
            min={today}
          />
          <FormInput
            id="orderId"
            name="Order ID"
            register={register}
            errors={errors}
            disabled={loading}
          />
          <h6 className="my-5 font-bold">Customer Information: </h6>
          <FormInput
            id="name"
            name="Name"
            register={register}
            errors={errors}
            disabled={loading}
          />
          <FormInput
            id="email"
            name="Email"
            register={register}
            errors={errors}
            disabled={loading}
          />
          <FormInput
            id="phone"
            name="Phone"
            register={register}
            errors={errors}
            disabled={loading}
          />

          <div className="flex w-full mt-4">
            <button
              type="submit"
              disabled={loading}
              className="flex flex-row items-center py-4 px-4 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
            >
              Submit
            </button>
          </div>
        </form>
      </WhiteBox>
    </div>
  );
}

export default NewRequest;
