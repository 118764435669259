import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

export default function NewDataTable(props) {
  const [filteredData, setFilteredData] = useState(props.data);
  const [filterText, setFilterText] = useState("");

  const filtering = (e) => {
    e.preventDefault();
    setFilterText(e.target.value);
  };

  useEffect(() => {
    if (filterText != "" && props.searchFields) {
      let compareFilter = filterText.toLowerCase();
      let filteredTable = props.data.filter((element) => {
        let included = false;
        for (var i = 0; i < props.searchFields.length; i++) {
          try {
            let compareItem;
            let searchTerm = props.searchFields[i];
            if (searchTerm.includes(".")) {
              let split = searchTerm.split(".");
              compareItem = element;
              for (var a = 1; a < split.length; a++) {
                compareItem = compareItem[split[a]];
              }
            } else {
              compareItem = element[searchTerm];
            }
            compareItem = compareItem.toLowerCase();
            if (compareItem.includes(compareFilter)) {
              included = true;
            }
          } catch (err) {}
        }
        return included;
      });

      setFilteredData(filteredTable);
    } else {
      setFilteredData(props.data);
    }
  }, [filterText]);

  const clear = () => {
    setFilterText("");
  };

  return (
    <div className="flex flex-col">
      {props.searchFields && (
        <div className="flex relative w-full justify-start mb-4">
          <div>
            <input
              type={"text"}
              className="
                    form-control
                    block
                    w-64
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                     pr-8
                    
                    focus:text-gray-700 focus:bg-white focus:outline-none
                "
              placeholder="Search"
              value={filterText}
              onChange={filtering}
            />
          </div>
          {filterText != "" && (
            <button
              className="absolute top-1/2 -translate-y-1/2"
              onClick={clear}
              style={{
                paddingLeft: 230,
              }}
            >
              <AiOutlineClose size={"1.25em"} />
            </button>
          )}
        </div>
      )}

      <DataTable columns={props.columns} data={filteredData} />
    </div>
  );
}
