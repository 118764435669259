export default function TableButton(props) {
  return (
    <button
      type="button"
      className="flex flex-row items-center mr-2 py-2 px-3 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
      onClick={props.onClick ? props.onClick : null}
    >
      {props.children}
    </button>
  );
}
