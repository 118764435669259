import React, { useRef, useEffect, useContext, useState } from "react";
import useApi from "../../../hooks/apiHook";
import { UserContext } from "../../../context/userContext";
import { useMatch } from "react-router-dom";

import handleError from "../../../functions/handleError";
import Loading from "../../../components/Loading";

import DataTable from "../../../components/DataTable";
import WhiteBox from "../../../components/WhiteBox";
import BackButton from "../../../components/BackButton";
import Moment from "moment";

function Log(props) {
    const ActionDesctions = {
        "createRequest": "Created Request for orderId",
        "readyRequest": "Item is placed for orderId",
        "collectRequest": "Item is collected for orderId",
        "resolveRequest": "orderId is resolved",
    };
    const { user, token } = useContext(UserContext);
    const { params } = useMatch("/dashboard/lockers/logs/:id");
    const { getAuditLogsByLocker, loading } = useApi();

    //getAllRequests
    const [logs, setLogs] = useState([]);

    const getAuditLogDetails = async () => {
        await getAuditLogsByLocker(token, params.id)
            .then((response) => {
                setLogs(response.logs);
                setFilteredLogs(response.logs);
            })
            .catch((err) => {
                if (err.message != "Logs not found") {
                    handleError(err.message);
                    setLogs([]);
                } else {
                    setLogs([]);
                }
            });
    };

    useEffect(() => {
        if (token) {
            getAuditLogDetails();
        }
    }, [token]);

    //columns
    const columns = [
        {
            name: "Locker",
            selector: (row) => row.locker.location,
            sortable: true,
        },
        {
            name: "Admin",
            selector: (row) =>
                `${row.admin && row.admin.email ? row.admin.email : ""}`,
            sortable: true,
        },
        {
            name: "Timestamp",
            selector: (row) =>
                `${Moment(row.timestamp).format("DD/MM/YYYY hh:mm")}`,
            sortable: true,
        },
        {
            name: "Action Description",
            selector: (row) => row.action,
            cell: (row) => {
                let description = ActionDesctions[row.action]
                return description.replace("orderId", row.request ? row.request.orderId : "<DELETED>");
            },
            sortable: true,
        }
    ];

    // search request
    const [searchData, setSearchData] = useState("");
    const [filteredLogs, setFilteredLogs] = useState([]);

    useEffect(() => {
        const resultOfLogTable = logs.filter((log) => {
            const filteredArray = log.slots.filter((inner) =>
                inner.number.toLowerCase().match(searchData.toLowerCase())
            );
            // console.log(filteredArray.map(x => x.number).toString().toLowerCase().match(searchData.toLowerCase())) // === filterlog
            return (
                log.locker.location
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                filteredArray
                    .map((x) => x.number)
                    .toString()
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                log.type.toLowerCase().match(searchData.toLowerCase()) ||
                log.request.orderId.toLowerCase().match(searchData.toLowerCase()) ||
                log.request.customer.email
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                Moment(log.timestamp)
                    .format("DD/MM/YYYY")
                    .toLowerCase()
                    .match(searchData.toLowerCase())
            );
        });
        setFilteredLogs(resultOfLogTable);
    }, [searchData]);
    const handleSearchData = (e) => {
        setSearchData(e.target.value);
    };
    // console.log(searchData)
    // console.log(filteredRequest)

    return (
        <div className="flex flex-col">
            <div className="flex flex-row mb-4 pb-4">
                <BackButton />
                <h2 className="text-4xl ml-2">Audit Logs</h2>
            </div>
            <WhiteBox>
                {loading && <Loading />}
                {!loading && (
                    <DataTable
                        // searchFields={["locker.location", "lockerSlot", "type", "orderId", "customer.email", "expiryPeriod", "uap"]}
                        columns={columns}
                        data={filteredLogs}
                        subHeader
                        subHeaderComponent
                        value={searchData}
                        onChange={handleSearchData}
                    />
                )}
            </WhiteBox>
        </div>
    );
}

export default Log;
