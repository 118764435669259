import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "tw-elements";
import Login from "./pages/Login";
import TwoFA from "./pages/TwoFA";

import { UserProvider } from "./context/userContext";

import reportWebVitals from "./reportWebVitals";
import Menu from "./components/Menu";

import routes from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <UserProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/2fa" element={<TwoFA />} />
                <Route path="/dashboard" element={<Menu />}>
                    {routes.map((val, index) => (
                        <Route path={val.path} element={val.element} />
                    ))}
                </Route>
            </Routes>
        </BrowserRouter>
    </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
