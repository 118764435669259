import "./twofa.css";
import React, { useState, useRef, useEffect, useContext } from "react";
import lockerImg from "../../img/lockers.jpg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserContext } from "../../context/userContext";
import apiHook from "../../hooks/apiHook";
import { useNavigate, Navigate } from "react-router-dom";
import * as yup from "yup";

import Swal from "sweetalert2";

const schema = yup
  .object({
    twofa: yup.string().required("2FA is required"),
  })
  .required();

function TwoFA(props) {
  const { faAuth } = apiHook();
  const { user, updateUser, token, faId, setFaId, faEmail, clearUser } = useContext(UserContext);
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if(faId == null || faEmail == null) {
      clearUser();
      navigate("/", { replace: true });
    }
  }, [])

  const onSubmit = (data) => {
    clearErrors();
    Swal.fire({
      title: "Loading",
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        let response = await faAuth(data.twofa, faId)
          .then((response) => {
            Swal.close();
            setFaId(null);
            updateUser(response.admin, response.token);
          })
          .catch((err) => {
            setError("twofa", { type: "custom", message: err.error });
            Swal.close();
          });
      },
    });
  };

  return (
    <div className="bg-slate-100 w-screen h-screen flex flex-row">
      {user && token && <Navigate to="/dashboard/lockers" replace={false} />}
      <div className="hidden md:flex flex-1 flex-col items-center justify-center">
        <img src={lockerImg} className="object-cover w-full h-full" />
      </div>
      <div className="w-screen md:w-2/3 lg:w-1/2 p-10 rounded-md bg-gray-50 shadow h-screen flex flex-col items-center justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="h-full w-4/5 flex flex-col justify-center items-center"
        >
          <h2 className="font-medium leading-tight text-3xl md:text-4xl text-center">
            2 Factor Authentication
          </h2>
          <h3 className="font-light leading-tight text-3xl my-4 text-center">
            Authenticate your Account
          </h3>
          <p className="font-light text-base text-center mb-5">
            Please confirm your account by entering the authentication code sent to {faEmail}
          </p>
          <div className="w-full">
            <div className="w-full form-floating mb-5">
              <input
                className="form-control
                                            block
                                            w-full
                                            px-3
                                            py-1.5
                                            text-base
                                            font-normal
                                            text-gray-700
                                            bg-white bg-clip-padding
                                            border border-solid border-gray-300
                                            rounded-lg
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="2FA Code"
                {...register("twofa")}
              />
              <label htmlFor="twofa" className="text-gray-700">
                2FA Code
              </label>
              {errors.twofa && (
                <div className="text-red-500">{errors.twofa?.message}</div>
              )}
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <button
                type="submit"
                className="w-full py-4 rounded-lg inline-block px-6 bg-blue-600 text-white font-medium text-s leading-tight uppercase shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Authenticate
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TwoFA;
