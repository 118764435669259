import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

export default function BackButton(props) {
  let navigate = useNavigate();
  return (
    <button
      type="button"
      className="flex flex-row items-center ml-4 py-2 px-3 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
      onClick={() => navigate(-1)}
    >
      <FaAngleLeft size="1.25em" />
    </button>
  );
}
