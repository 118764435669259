export default function Loading() {
  return (
    <div className="flex flex-row w-full items-center justify-center">
      <div
        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-800"
        role="status"
      >
        <span className="visually-hidden"></span>
      </div>
    </div>
  );
}
