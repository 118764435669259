import { useEffect, useState } from "react";
import Creatable from "react-select";

export default function AdminCreatable(props) {
    const [inputValue, setInputValue] = useState("");
    const [value, setValue] = useState([]);

    const createOption = (label) => ({
        label,
        value: label,
    });

    const handleChange = (value, actionMeta) => {
        setValue(value);
        props.setValue(props.id, handleValues(value));
    };
    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
    };

    const handleValues = (valueArray) => {
        let returnArray = [];
        for (var i = 0; i < valueArray.length; i++) {
            returnArray.push(valueArray[i].value);
        }
        return returnArray;
    };
    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
                setInputValue("");
                let newValue = [...value, createOption(inputValue)];
                setValue(newValue);
                props.setValue(props.id, handleValues(newValue));
                event.preventDefault();
        }
    };

    useEffect(() => {
        if (props.defaultValues && props.defaultValues?.length > 0) {
            let valueArray = [];
            for (var i = 0; i < props.defaultValues.length; i++) {
                valueArray.push(createOption(props.defaultValues[i]));
            }
            setValue(valueArray);
        }
    }, [props.defaultValues]);

    return (
        <div className="mb-4 w-full">
            <Creatable
                menuIsOpen={false}
                inputValue={inputValue}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                value={value}
                {...props}
            />
            {props.errors[props.id] && (
                <div className="text-red-500">
                    {props.errors[props.id][0].message}
                </div>
            )}
        </div>
    );
}
