import React, { useRef, useEffect, useContext, useState } from "react";
import useApi from "../../../hooks/apiHook";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/userContext";

import handleError from "../../../functions/handleError";
import Loading from "../../../components/Loading";

import DataTable from "../../../components/DataTable";
import WhiteBox from "../../../components/WhiteBox";
import TableButton from "../../../components/TableButton";

import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import Switch from '@mui/material/Switch';
import handleSuccess from "../../../functions/handleSuccess";

const { io } = require("socket.io-client");

function LockerTable(props) {
    const { user, token } = useContext(UserContext);
    const { getLockers, deleteLocker, updateLocker, loading } = useApi();

    const [lockers, setLockers] = useState([]);
    const [socket, setSocket] = useState(null);

    const [lockerStatus, setLockerStatus] = useState([]);
    const backend_url = "https://lockersocket.epitome.com.sg";

    useEffect(() => {
        if (token) {
            let newSocket = io(backend_url, {
                auth: {
                    token: token,
                },
            });

            newSocket.on("connect", () => {
                //handleSuccess("Locker Live Update Online");
                console.log("Locker Live Update Online");
            });

            newSocket.on("connect_error", () => {
                console.log("Fail to connect to Locker Live Update");
                // const Toast = Swal.mixin({
                //     toast: true,
                //     position: "top-end",
                //     showConfirmButton: false,
                //     timer: 3000,
                //     timerProgressBar: true,
                // });
                // Toast.fire({
                //     icon: "error",
                //     title: "Fail to connect to Locker Live Update",
                // });
            });

            newSocket.on("lockerUpdate", (data) => {
                setLockerStatus(data.lockers);
            });

            setSocket(newSocket);
        }

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [token]);

    const columns = [
        {
            name: "Status",
            width: "90px",
            selector: (row) => lockerStatus.includes(row._id),
            sortable: true,
            cell: (row) => {
                let active = lockerStatus.includes(row._id);
                return (
                    <div>
                        <div
                            title={active ? "Online" : "Offline"}
                            className={`${active
                                ? "bg-emerald-400 animate-pulse"
                                : "bg-red-600"
                                } w-4 h-4 rounded-full`}
                        ></div>
                    </div>
                );
            },
        },
        {
            name: "Location",
            selector: (row) => row.location,
            sortable: true,
        },
        {
            name: "Hardware ID",
            selector: (row) => row.hardwareId,
            sortable: true,
        },
        {
            name: "Admin PIN",
            selector: (row) => row.adminPin,
            sortable: true,
        },
        {
            name: "Facial Recognition",
            grow: 0.5,
            selector: (row) => row.selfieEnabled,
            cell: (row, index, column, id) => {
                return (
                    <Switch
                        defaultChecked={row.selfieEnabled}
                        onChange={(event) => {
                            handleFacialRecognitionChange(event, row);
                        }}
                        name="selfieEnabled"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                );
            },
        },
        {
            name: "Options",
            grow: 1.5,
            cell: (row, index, column, id) => {
                return (
                    <div className="flex flex-row">
                        <Link
                            to={`/dashboard/lockers/logs/${row._id}`}
                            state={{ name: row.location }}
                        >
                            <TableButton>View Logs</TableButton>
                        </Link>
                        <Link
                            to={`/dashboard/lockers/slots/${row._id}`}
                            state={{ name: row.location }}
                        >
                            <TableButton>View Slots</TableButton>
                        </Link>
                        <Link to={`/dashboard/lockers/edit/${row._id}`}>
                            <TableButton>Edit</TableButton>
                        </Link>
                        <TableButton
                            onClick={() => {
                                deleteSelectedLocker(row._id, row.location);
                            }}
                        >
                            Delete
                        </TableButton>
                    </div>
                );
            },
        },
    ];

    const handleFacialRecognitionChange = (event, row) => {
        let updateObject = {
            selfieEnabled: event.target.checked,
        };
        updateLocker(token, row._id, updateObject)
            .then((response) => {
                handleSuccess("Locker Updated");
                //let newLockers = [...lockers];
                //newLockers[newLockers.findIndex((ele) => ele._id == row._id)].selfieEnabled = event.target.checked
                getLockerDetails();
                //setLockers(newLockers);
            })
            .catch((err) => {
                handleError(err);
            })
    }

    const deleteSelectedLocker = (id, location) => {
        Swal.fire({
            icon: "warning",
            title: `Are you sure you want to delete ${location}?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Loading",
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();
                        let response = await deleteLocker(token, id)
                            .then((response) => {
                                handleSuccess("Locker deleted successfully");
                                getLockerDetails();
                            })
                            .catch((err) => {
                                handleError(err);
                            });
                    },
                });
            }
        });
    };

    const getLockerDetails = async () => {
        await getLockers(token)
            .then((response) => {
                setLockers(response.lockers);
                setFilteredLocker(response.lockers);
            })
            .catch((err) => {
                if (err.message != "Lockers not found") {
                    handleError(err.message);
                    setLockers([]);
                } else {
                    setLockers([]);
                }
            });
    };

    useEffect(() => {
        if (token) {
            getLockerDetails();
        }
    }, [token]);
    // search locker
    const [searchData, setSearchData] = useState("");
    const [filteredLocker, setFilteredLocker] = useState([]);
    useEffect(() => {
        const resultOfLockerTable = lockers.filter((locker) => {
            return (
                locker.location.toLowerCase().match(searchData.toLowerCase()) ||
                locker.hardwareId
                    .toLowerCase()
                    .match(searchData.toLowerCase()) ||
                locker.adminPin.toLowerCase().match(searchData.toLowerCase())
            );
        });
        setFilteredLocker(resultOfLockerTable);
    }, [searchData]);
    const handleSearchData = (e) => {
        setSearchData(e.target.value);
    };
    console.log(searchData);
    console.log(filteredLocker);

    return (
        <div className="flex flex-col">
            <div className="flex flex-row">
                <h2 className="text-4xl">Lockers</h2>
            </div>
            <div className="flex flex-row mb-4 justify-end">
                <Link to="/dashboard/lockers/new">
                    <button
                        type="button"
                        className="flex flex-row items-center ml-4 py-2 px-3 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                    >
                        Create New Locker{" "}
                        <FaPlus size="1.25em" className="ml-2" />
                    </button>
                </Link>
            </div>
            <WhiteBox>
                {loading && <Loading />}
                {!loading && (
                    <DataTable
                        // searchFields={["location", "hardwareId", "adminPin"]}
                        columns={columns}
                        data={filteredLocker}
                        subHeader
                        subHeaderComponent
                        value={searchData}
                        onChange={handleSearchData}
                    />
                )}
            </WhiteBox>
        </div>
    );
}

export default LockerTable;
