import React, { useRef, useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMatch, useNavigate } from "react-router-dom";
import apiHook from "../../../hooks/apiHook";
import * as yup from "yup";

import Swal from "sweetalert2";
import { UserContext } from "../../../context/userContext";

import handleError from "../../../functions/handleError";
import handleSuccess from "../../../functions/handleSuccess";

import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import WhiteBox from "../../../components/WhiteBox";
import Loading from "../../../components/Loading";

import Creatable from "../../../components/Creatable";

const schema = yup
    .object({
        location: yup.string().required("Location is required"),
        hardwareId: yup.string().required("Hardware ID is required"),
        adminPin: yup.string().required("Admin PIN is required"),
        adminEmails: yup.array().of(yup.string().email("Invalid Email")),
    })
    .required();

function EditLocker(props) {
    const { getLocker, updateLocker, loading } = apiHook();
    const [loaded, setLoaded] = useState(false);
    const { token } = useContext(UserContext);
    const { params } = useMatch("/dashboard/lockers/edit/:id");

    const [defaultAdminEmails, setDefaultAdminEmails] = useState(null);

    let navigate = useNavigate();

    useEffect(() => {
        if (token) {
            initLocker(token, params.id);
        }
    }, [token]);

    const initLocker = async (token, id) => {
        await getLocker(token, params.id)
            .then((response) => {
                console.log(response);
                let locker = response.locker;
                if (locker) {
                    setValue("location", locker.location);
                    setValue("hardwareId", locker.hardwareId);
                    setValue("adminPin", locker.adminPin);
                    setValue("address", locker.address);
                    setValue("adminEmails", locker.adminEmails);
                    setDefaultAdminEmails(locker.adminEmails);
                    setLoaded(true);
                }
            })
            .catch((err) => {
                handleError(err.message);
            });
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
        setError,
        clearErrors,
        trigger,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        clearErrors();

        Swal.fire({
            title: "Loading",
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await updateLocker(token, params.id, data)
                    .then((response) => {
                        handleSuccess("Locker updated successfully");
                        navigate("/dashboard/lockers", {
                            replace: true,
                        });
                    })
                    .catch((err) => {
                        handleError(err.message);
                    });
            },
        });
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row mb-4 pb-4">
                <BackButton />
                <h2 className="text-4xl ml-2">Edit Locker</h2>
            </div>
            <WhiteBox>
                {loaded && (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-full flex flex-col"
                        onKeyDown={checkKeyDown}
                    >
                        <FormInput
                            id="location"
                            name="Location"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <FormInput
                            id="hardwareId"
                            name="Hardware ID"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <FormInput
                            id="adminPin"
                            name="Admin PIN"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <label
                            htmlFor="adminEmails"
                            className="form-label inline-block mb-2 text-gray-800 text-md"
                        >
                            Admin Emails (Email Address(es) to send Admin Emails
                            to)
                        </label>
                        <Creatable
                            isMulti
                            id="adminEmails"
                            name="adminEmails"
                            placeholder="Admin Emails"
                            setValue={setValue}
                            errors={errors}
                            disabled={loading}
                            register={register}
                            defaultValues={defaultAdminEmails}
                        />
                        <FormInput
                            id="address"
                            type="textarea"
                            name="Address"
                            rows={2}
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <div className="flex w-full mt-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className="flex flex-row items-center py-4 px-4 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                )}
                {!loaded && <Loading />}
            </WhiteBox>
        </div>
    );
}

export default EditLocker;
