import React, { useEffect, useContext, useState } from "react";
import BackButton from "../../../components/BackButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useMatch } from "react-router-dom";
import useApi from "../../../hooks/apiHook";
import * as yup from "yup";
import Swal from "sweetalert2";
import { UserContext } from "../../../context/userContext";
import handleError from "../../../functions/handleError";
import handleSuccess from "../../../functions/handleSuccess";
import FormInput from "../../../components/FormInput";
import WhiteBox from "../../../components/WhiteBox";
import Select from "react-select";
import Loading from "../../../components/Loading";
import moment from "moment";
import _ from "lodash";

const schema = yup
    .object({
        orderId: yup.string().required("Order ID is required"),
        expiryDate: yup.string().required("Expiry Date is required"),
        name: yup.string().required("Customer Name is required"),
        email: yup
            .string()
            .required("Customer Email is required")
            .email("Invalid Email Format"),
        phone: yup
            .number()
            .typeError("That doesn't look like a phone number")
            .positive("A phone number can't start with a minus")
            .integer("A phone number can't include a decimal point")
            .required("A phone number is required"),
        uap: yup.string().required(),
    })
    .required();

function EditRequest(props) {
    let navigate = useNavigate();
    const { token } = useContext(UserContext);
    const {
        getLockers,
        loading,
        getAvailableLockerSlotsByLocker,
        updateRequest,
        getRequest,
    } = useApi();
    const [loaded, setLoaded] = useState(false);
    const { params } = useMatch("/dashboard/requests/edit/:id");

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    // console.log(getValues("expiryDate").substr(0, 10))

    useEffect(() => {
        if (token) {
            initRequest(token, params.id);
        }
    }, [token]);

    const initRequest = async (token, id) => {
        await getRequest(token, params.id)
            .then((response) => {
                let request = response.request;
                if (request) {
                    if (request.type === "purchase") {
                        setValue("type", {
                            value: "purchase",
                            label: "Purchase",
                        });
                    } else {
                        setValue("type", {
                            value: "rent",
                            label: "Rent",
                        });
                    }
                    setValue("lockerLocation", {
                        value: request.locker._id,
                        label: request.locker.location,
                    });
                    getDetails(request.locker._id);
                    setValue("lockerSlots", request.slots);
                    setSelectedSlots(request.slots);
                    // console.log(request.validityPeriod.substr(0, 10))
                    setValue(
                        "expiryDate",
                        request.validityPeriod.substr(0, 10)
                    );
                    setValue("orderId", request.orderId);
                    setValue("uap", request.uap);
                    setValue("name", request.customer.name);
                    setValue("email", request.customer.email);
                    setValue("phone", request.customer.phone);
                    setLoaded(true);
                }
            })
            .catch((err) => {
                handleError(err.message);
            });
    };
    //get all lockers list
    const [lockers, setLockers] = useState([]);
    const [locker, setLocker] = useState(null);
    // console.log(locker)
    const getLockerDetails = async () => {
        await getLockers(token)
            .then((response) => {
                setLockers(response.lockers);
            })
            .catch((err) => {
                if (err.message != "Lockers not found") {
                    handleError(err.message);
                    setLockers([]);
                } else {
                    setLockers([]);
                }
            });
    };

    useEffect(() => {
        if (token) {
            getLockerDetails();
        }
    }, [token]);

    //get lockers slot list id
    //locker = params.id /id
    const [lockerSlots, setLockerSlots] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const getDetails = async (lockerId) => {
        await getAvailableLockerSlotsByLocker(token, lockerId ? lockerId : locker)
            .then((response) => {
                setLockerSlots(response.slots);
            })
            .catch((err) => {
                if (err.message != "Slots not found") {
                    handleError(err.message);
                } else {
                    setLockerSlots([]);
                }
            });
    };
    useEffect(() => {
        if ((token, locker)) {
            getDetails();
        }
    }, [token, locker]);

    //handle submit edit request
    const onSubmit = (data) => {
        clearErrors();
        Swal.fire({
            title: "Loading",
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await updateRequest(token, params.id, {
                    // locker: locker,
                    // slots: selectedSlots.map((x) => x.value),
                    type: selectType,
                    validityPeriod: data.expiryDate,
                    orderId: data.orderId,
                    uap: data.uap,
                    customer: {
                        name: data.name,
                        email: data.email,
                        phone: data.phone,
                    },
                })
                    .then((response) => {
                        handleSuccess("Locker Request updated successfully");
                        navigate("/dashboard/requests", { replace: true });
                    })
                    .catch((err) => {
                        handleError(err.message);
                    });
            },
        });
    };

    //type options
    const [selectType, setSelectType] = useState("");
    // console.log(selectType);

    let options = [
        { value: "purchase", label: "Purchase" },
        { value: "rent", label: "Rent" },
    ];

    const handleChange = (data) => {
        setSelectType(data.value);
    };
    //date of today
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;

    return (
        <div className="flex flex-col">
            <div className="flex flex-row mb-4 pb-4">
                <BackButton />
                <h2 className="text-4xl ml-2">Edit Request</h2>
            </div>
            <WhiteBox>
                {loaded && !_.isEmpty(getValues()) && (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-full flex flex-col px-5"
                    >
                        <div className="mb-4 w-full">
                            <label
                                htmlFor="lockerLocation"
                                className="form-label inline-block mb-2 text-gray-800 text-md"
                            >
                                Locker Location
                            </label>
                            <Select
                                name="lockerLocation"
                                onChange={(data) => setLocker(data.value)}
                                options={lockers.map((option) => ({
                                    value: option._id,
                                    label: option.location,
                                }))}
                                placeholder="Select Locker Location"
                                defaultValue={{
                                    label: getValues("lockerLocation").label,
                                    value: getValues("lockerLocation").value,
                                }}
                                isDisabled={true}
                            />
                        </div>
                        <div className="mb-4 w-full">
                            <label
                                htmlFor="lockerSlot"
                                className="form-label inline-block mb-2 text-gray-800 text-md"
                            >
                                Locker Slots
                            </label>
                            <Select
                                isMulti
                                name="lockerSlot"
                                options={lockerSlots.map((option) => ({
                                    value: option._id,
                                    label: `${option.number} - Size ${option.size.toUpperCase()}`,
                                }))}
                                className="basic-multi-select "
                                classNamePrefix="select"
                                placeholder="Select Locker Slots"
                                onChange={(data) => {
                                    setSelectedSlots(data);
                                }}
                                defaultValue={getValues("lockerSlots").map(
                                    (x) => ({
                                        value: x._id,
                                        label: x.number,
                                    })
                                )}
                                isDisabled={true}
                            />
                        </div>
                        <div className="mb-4 w-full">
                            <label
                                htmlFor="type"
                                className="form-label inline-block mb-2 text-gray-800 text-md"
                            >
                                Type
                            </label>
                            <Select
                                name="type"
                                onChange={handleChange}
                                options={options}
                                placeholder="Select Type"
                                defaultValue={{
                                    label: getValues(["type"])[0].label,
                                    value: getValues(["type"])[0].value,
                                }}
                                isDisabled={true}
                            />
                        </div>

                        <FormInput
                            id="expiryDate"
                            name="Expiry Date"
                            type="date"
                            register={register}
                            errors={errors}
                            disabled={loading}
                            min={today}
                            defaultValue={getValues("expiryDate").substr(0, 10)}
                        />
                        <FormInput
                            id="orderId"
                            name="Order ID"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <FormInput
                            id="uap"
                            name="UAP"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <h6 className="my-5 font-bold">
                            Customer Information:
                        </h6>
                        <FormInput
                            id="name"
                            name="Name"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <FormInput
                            id="email"
                            name="Email"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />
                        <FormInput
                            id="phone"
                            name="Phone"
                            register={register}
                            errors={errors}
                            disabled={loading}
                        />

                        <div className="flex w-full mt-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className="flex flex-row items-center py-4 px-4 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                )}
                {!loaded && <Loading />}
            </WhiteBox>
        </div>
    );
}

export default EditRequest;
