export default function getSlotStatus(num) {
  const statuses = {
    1: "Reserved",
    2: "Ready",
    3: "Expired",
    4: "Resolved",
  };

  return statuses[num];
}
