import React, { useContext, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";

import routes from "../../routes";
import { UserContext } from "../../context/userContext";

export default function Menu() {
  const { clearUser, user, token } = useContext(UserContext);
  let navigate = useNavigate();

  const logout = () => {
    clearUser();
    navigate("/", { replace: true });
  };

  let location = useLocation();

  return (
    <div className="flex flex-row h-screen">
      <div className="flex flex-col bg-gray-800 w-60 text-white px-4 py-4" style={{ height: "100vh" }}>
        <h2 className="p-4 text-xl font-bold">Locker System</h2>
        {routes.map((val, index) => {
          if (val.notOnList) {
            return;
          }
          let active = location.pathname.includes(val.path);
          if(active && val.name == "Audit Logs" && location.pathname.includes("lockers/logs")){
            active = false;
          }
          return (
            <Link to={`/dashboard/${val.path}`}>
              <button
                className={`p-4 ${active ? "bg-gray-600 text-white" : "text-gray-300"
                  } mt-2 text-left rounded-lg w-full rounded-t-lg hover:bg-gray-600 hover:text-white transition duration-300 ease-in-out`}
              >
                <div className="flex flex-row items-center">
                  {val.icon}
                  <div className="pl-2 text">{val.name}</div>
                </div>
              </button>
            </Link>
          );
        })}
        <button
          className={`p-4 mt-2 text-left rounded-lg w-full rounded-t-lg hover:bg-gray-600 hover:text-white transition duration-300 ease-in-out`}
          onClick={logout}
        >
          <div className="flex flex-row items-center">
            <MdLogout size={"1.25em"} />
            <div className="pl-2 text">Logout</div>
          </div>
        </button>
      </div>
      <div className="p-8 bg-gray-100 flex-1" style={{ height: "100vh", overflow: "auto" }}>
        <Outlet />
      </div>
    </div>
  );
}
