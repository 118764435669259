import React, { useRef, useEffect, useContext, useState } from "react";
import useApi from "../../../hooks/apiHook";
import { Link, useMatch, useLocation } from "react-router-dom";
import { UserContext } from "../../../context/userContext";

import handleError from "../../../functions/handleError";
import Loading from "../../../components/Loading";

import DataTable2 from "../../../components/DataTable2";
import WhiteBox from "../../../components/WhiteBox";
import TableButton from "../../../components/TableButton";

import { FaPlus } from "react-icons/fa";
import getSlotStatus from "../../../functions/getSlotStatus";
import BackButton from "../../../components/BackButton";
import Swal from "sweetalert2";
import handleSuccess from "../../../functions/handleSuccess";

function LockerSlotsTable(props) {
    const { user, token } = useContext(UserContext);
    const { params } = useMatch("/dashboard/lockers/slots/:id");

    const {
        getLockerSlotsByLocker,
        deleteLockerSlot,
        openLockerSlot,
        loading,
    } = useApi();

    const [lockerSlots, setLockerSlots] = useState([]);

    const location = useLocation();
    const { name } = location.state;

    const columns = [
        {
            name: "Number",
            selector: (row) => row.number,
            sortable: true,
        },
        {
            name: "Size",
            selector: (row) => row.size,
            cell: (row) => {
                if (row.size === "sm") {
                    return <div>Small</div>;
                } else if (row.size === "md") {
                    return <div>Medium</div>;
                } else {
                    return <div>Large</div>;
                }
            },
            sortable: true,
        },
        {
            name: "Status",
            cell: (row, index, column, id) => {
                if (row.request) {
                    return <div>{getSlotStatus(row.request.status)}</div>;
                } else {
                    return <div>Empty</div>;
                }
            },
        },
        {
            name: "Hex Code",
            selector: (row) => {
                console.log(row);
                return row.hexCode;
            },
            sortable: true,
        },
        {
            name: "Options",
            cell: (row, index, column, id) => {
                return (
                    <div className="flex flex-row">
                        <TableButton
                            onClick={() => {
                                openSlot(row._id, row.number);
                            }}
                        >
                            Open Remotely
                        </TableButton>
                        <Link to={`/dashboard/lockers/slots/edit/${row._id}`}>
                            <TableButton>Edit</TableButton>
                        </Link>
                        <TableButton
                            onClick={() => {
                                deleteSelectedSlot(row._id, row.number);
                            }}
                        >
                            Delete
                        </TableButton>
                    </div>
                );
            },
        },
    ];

    const openSlot = (id, number) => {
        Swal.fire({
            icon: "warning",
            title: `Are you sure you want to open Slot ${number}?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Loading",
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();
                        let response = await openLockerSlot(token, id)
                            .then((response) => {
                                handleSuccess(
                                    "Locker Slot opened successfully"
                                );
                            })
                            .catch((err) => {
                                handleError(err);
                            });
                    },
                });
            }
        });
    };

    const getDetails = async () => {
        await getLockerSlotsByLocker(token, params.id)
            .then((response) => {
                setLockerSlots(response.slots);
            })
            .catch((err) => {
                if (err.message != "Slots not found") {
                    handleError(err.message);
                } else {
                    setLockerSlots([]);
                }
            });
    };

    const deleteSelectedSlot = (id, number) => {
        Swal.fire({
            icon: "warning",
            title: `Are you sure you want to delete Slot ${number}?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Loading",
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();
                        let response = await deleteLockerSlot(token, id)
                            .then((response) => {
                                handleSuccess(
                                    "Locker Slot deleted successfully"
                                );
                                getDetails();
                            })
                            .catch((err) => {
                                handleError(err);
                            });
                    },
                });
            }
        });
    };

    useEffect(() => {
        if (token && params) {
            getDetails();
        }
    }, [token, params]);

    return (
        <div className="flex flex-col">
            <div className="flex flex-row">
                <BackButton />
                <h2 className="text-4xl ml-2">Locker Slots: {name}</h2>
            </div>
            <div className="flex flex-row mb-4 justify-end">
                <Link to={`/dashboard/lockers/slots/new/${params.id}`}>
                    <button
                        type="button"
                        className="flex flex-row items-center ml-4 py-2 px-3 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                    >
                        Create New Locker Slot{" "}
                        <FaPlus size="1.25em" className="ml-2" />
                    </button>
                </Link>
            </div>
            <WhiteBox>
                {loading && <Loading />}
                {!loading && (
                    <DataTable2
                        searchFields={[
                            "number",
                            "size",
                            "request.status",
                            "hexCode",
                        ]}
                        columns={columns}
                        data={lockerSlots}
                        // to={`/dashboard/lockers/slots/new/${params.id}`}
                    />
                )}
            </WhiteBox>
        </div>
    );
}

export default LockerSlotsTable;
