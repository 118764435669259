import React, { useRef, useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import apiHook from "../../../hooks/apiHook";
import * as yup from "yup";

import Swal from "sweetalert2";
import { UserContext } from "../../../context/userContext";

import handleError from "../../../functions/handleError";
import handleSuccess from "../../../functions/handleSuccess";

import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import WhiteBox from "../../../components/WhiteBox";

import Creatable from "../../../components/Creatable";

const schema = yup
    .object({
        location: yup.string().required("Location is required"),
        hardwareId: yup.string().required("Hardware ID is required"),
        adminPin: yup.string().required("Admin PIN is required"),
        sm: yup
            .number()
            .typeError("Invalid Value")
            .required("Amount is required")
            .min(0, "Amount must be positive")
            .integer("Invalid Amount"),
        md: yup
            .number()
            .typeError("Invalid Value")
            .required("Amount is required")
            .min(0, "Amount must be positive")
            .integer("Invalid Amount"),
        lg: yup
            .number()
            .typeError("Invalid Value")
            .required("Amount is required")
            .min(0, "Amount must be positive")
            .integer("Invalid Amount"),
        adminEmails: yup.array().of(yup.string().email("Invalid Email")),
    })
    .required();

function NewLocker(props) {
    const { createLocker, loading } = apiHook();
    const { token } = useContext(UserContext);

    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        if (data.sm <= 0 && data.md <= 0 && data.lg <= 0) {
            setError("sm", {
                type: "custom",
                message: "There must be at least 1 slot available.",
            });
            setError("md", {
                type: "custom",
                message: "There must be at least 1 slot available.",
            });
            setError("lg", {
                type: "custom",
                message: "There must be at least 1 slot available.",
            });
            return;
        }
        clearErrors();
        Swal.fire({
            title: "Loading",
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await createLocker(token, {
                    location: data.location,
                    hardwareId: data.hardwareId,
                    adminPin: data.adminPin,
                    slots: {
                        sm: data.sm,
                        md: data.md,
                        lg: data.lg,
                    },
                    address: data.address,
                    adminEmails: data.adminEmails,
                })
                    .then((response) => {
                        handleSuccess("Locker created successfully");
                        navigate("/dashboard/lockers", { replace: true });
                    })
                    .catch((err) => {
                        handleError(err.message);
                    });
            },
        });
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row mb-4 pb-4">
                <BackButton />
                <h2 className="text-4xl ml-2">New Locker</h2>
            </div>
            <WhiteBox>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full flex flex-col"
                    onKeyDown={checkKeyDown}
                >
                    <FormInput
                        id="location"
                        name="Location"
                        register={register}
                        errors={errors}
                        disabled={loading}
                    />
                    <FormInput
                        id="hardwareId"
                        name="Hardware ID"
                        register={register}
                        errors={errors}
                        disabled={loading}
                    />
                    <FormInput
                        id="adminPin"
                        name="Admin PIN"
                        register={register}
                        errors={errors}
                        disabled={loading}
                    />
                    <label
                        htmlFor="adminEmails"
                        className="form-label inline-block mb-2 text-gray-800 text-md"
                    >
                        Admin Emails (Email Address(es) to send Admin Emails to)
                    </label>
                    <Creatable
                        isMulti
                        id="adminEmails"
                        name="adminEmails"
                        placeholder="Admin Emails"
                        setValue={setValue}
                        errors={errors}
                        disabled={loading}
                        register={register}
                    />
                    <FormInput
                        id="address"
                        type="textarea"
                        name="Address"
                        rows={2}
                        register={register}
                        errors={errors}
                        disabled={loading}
                    />
                    <h2 className="mb-4 font-semibold text-xl">
                        Locker Slots Setup
                    </h2>
                    <FormInput
                        id="sm"
                        name="Small"
                        type="number"
                        register={register}
                        defaultValue={0}
                        errors={errors}
                        disabled={loading}
                    />
                    <FormInput
                        id="md"
                        name="Medium"
                        type="number"
                        register={register}
                        defaultValue={0}
                        errors={errors}
                        disabled={loading}
                    />
                    <FormInput
                        id="lg"
                        name="Large"
                        type="number"
                        register={register}
                        defaultValue={0}
                        errors={errors}
                        disabled={loading}
                    />
                    <div className="flex w-full mt-4">
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex flex-row items-center py-4 px-4 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </WhiteBox>
        </div>
    );
}

export default NewLocker;
