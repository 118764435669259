import React, { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMatch, useNavigate } from "react-router-dom";
import apiHook from "../../../hooks/apiHook";
import * as yup from "yup";

import Swal from "sweetalert2";
import { UserContext } from "../../../context/userContext";

import handleError from "../../../functions/handleError";
import handleSuccess from "../../../functions/handleSuccess";

import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import WhiteBox from "../../../components/WhiteBox";
import Loading from "../../../components/Loading";
import Select from "react-select";

const schema = yup
    .object({
        number: yup.string().required("Number is required"),
        hexCode: yup.string().required("Hex Code is required"),
        // size: yup.string().required("Size is required"),
    })
    .required();

function EditLockerSlot(props) {
    const { getLockerSlotsById, updateLockerSlot, loading } = apiHook();
    const { token } = useContext(UserContext);
    const [loaded, setLoaded] = useState(false);
    const { params } = useMatch("/dashboard/lockers/slots/edit/:id");
    const [selectSize, setSelectSize] = useState("");

    console.log(selectSize);

    let navigate = useNavigate();

    useEffect(() => {
        if (token) {
            initLockerSlot(token, params.id);
        }
    }, [token]);

    const initLockerSlot = async (token, id) => {
        await getLockerSlotsById(token, params.id)
            .then((response) => {
                console.log(response);
                let slot = response.slot;
                if (slot) {
                    setSelectSize(slot.size);
                    if (slot.size === "sm") {
                        setValue("size", {
                            value: "sm",
                            label: "Small",
                        });
                    } else if (slot.size === "md") {
                        setValue("size", {
                            value: "md",
                            label: "Medium",
                        });
                    } else {
                        setValue("size", {
                            value: "lg",
                            label: "Large",
                        });
                    }
                    setValue("number", slot.number);
                    setValue("hexCode", slot.hexCode);
                    setLoaded(true);
                }
            })
            .catch((err) => {
                handleError(err.message);
            });
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        setError,
        clearErrors,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        console.log(selectSize);
        clearErrors();
        Swal.fire({
            title: "Loading",
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await updateLockerSlot(token, params.id, {
                    size: selectSize,
                    number: data.number,
                    hexCode: data.hexCode,
                })
                    .then((response) => {
                        handleSuccess("Locker slot edited successfully");
                        navigate(-1, {
                            replace: true,
                        });
                    })
                    .catch((err) => {
                        handleError(err.message);
                    });
            },
        });
    };

    let options = [
        { value: "sm", label: "Small" },
        { value: "md", label: "Medium" },
        { value: "lg", label: "Large" },
    ];

    const handleChange = (data) => {
        setSelectSize(data.value);
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row mb-4 pb-4">
                <BackButton />
                <h2 className="text-4xl ml-2">Edit Locker Slot</h2>
            </div>
            <WhiteBox>
                {loaded && (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-full flex flex-col"
                    >
                        <h2 className="mb-4 font-semibold text-xl">
                            Edit Locker Slots Setup
                        </h2>
                        <div className="w-full flex">
                            <div className="mb-3 xl:w-full">
                                <label className="form-label inline-block mb-2 text-gray-800 text-md">
                                    Size
                                </label>
                                <Select
                                    id="size"
                                    name="size"
                                    defaultValue={{
                                        label: getValues(["size"])[0].label,
                                        value: getValues(["size"])[0].value,
                                    }}
                                    onChange={handleChange}
                                    options={options}
                                />
                            </div>
                        </div>
                        <FormInput
                            id="number"
                            name="Number"
                            register={register}
                            defaultValue=""
                            errors={errors}
                            disabled={loading}
                        />
                        <FormInput
                            id="hexCode"
                            name="Hex Code"
                            register={register}
                            defaultValue=""
                            errors={errors}
                            disabled={loading}
                        />
                        <div className="flex w-full mt-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className="flex flex-row items-center py-4 px-4 bg-gray-800 text-white font-medium leading-tight rounded-full hover:shadow-lg hover:bg-gray-100 hover:text-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-300 ease-in-out"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                )}
                {!loaded && <Loading />}
            </WhiteBox>
        </div>
    );
}

export default EditLockerSlot;
