import { useState, createContext, useEffect } from "react";

const UserContext = createContext();

function UserProvider(props) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [faId, setFaId] = useState(null);
  const [faEmail, setFaEmail] = useState(null);

  const updateUser = (user, token) => {
    setUser(user);
    setToken(token);

    //set token to memory
    localStorage.setItem("locker_token", token);
    localStorage.setItem("locker_user", JSON.stringify(user));
  };

  const clearUser = () => {
    localStorage.removeItem("locker_token");
    localStorage.removeItem("locker_user");
    setUser(null);
    setToken(null);
  };

  useEffect(() => {
    setToken(localStorage.getItem("locker_token"));
    setUser(JSON.parse(localStorage.getItem("locker_user")));
  }, []);

  let values = {
    token,
    user,
    updateUser,
    clearUser,
    faId,
    setFaId,
    faEmail, 
    setFaEmail
  };

  return (
    <UserContext.Provider value={values}>{props.children}</UserContext.Provider>
  );
}

export { UserContext, UserProvider };
