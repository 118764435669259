import LockerTable from "./pages/Locker/LockerTable";
import NewLocker from "./pages/Locker/NewLocker";
import NewLockerSlot from "./pages/LockerSlots/NewLockerSlot";
import EditLocker from "./pages/Locker/EditLocker";
import EditLockerSlot from "./pages/LockerSlots/EditLockerSlot";
import LockerSlots from "./pages/LockerSlots/LockerSlotTable";
import Request from "./pages/Request/RequestTable";
import AuditLog from "./pages/AuditLog/LogTable";
import AuditLogLocker from "./pages/AuditLog/LogTableLocker";
import { FaWarehouse, FaRegListAlt } from "react-icons/fa";
import EditRequest from "./pages/Request/EditRequest";
import NewRequest from "./pages/Request/NewRequest";

export default [
    {
        name: "Lockers",
        element: <LockerTable />,
        path: "lockers",
        icon: <FaWarehouse size={"1.25em"} />,
    },
    {
        name: "New Locker",
        element: <NewLocker />,
        path: "lockers/new",
        notOnList: true,
    },
    {
        name: "Edit Locker",
        element: <EditLocker />,
        path: "lockers/edit/:id",
        notOnList: true,
    },
    {
        name: "Locker Slot",
        element: <LockerSlots />,
        path: "lockers/slots/:id",
        notOnList: true,
    },
    {
        name: "Requests",
        element: <Request />,
        path: "requests",
        icon: <FaRegListAlt size={"1.25em"} />,
    },
    {
        name: "New Request",
        element: <NewRequest />,
        path: "requests/new",
        notOnList: true,
    },
    {
        name: "Edit Request",
        element: <EditRequest />,
        path: "requests/edit/:id",
        notOnList: true,
    },
    {
        name: "Locker Slot",
        element: <LockerSlots />,
        path: "lockers/slots/:id",
        notOnList: true,
    },
    {
        name: "New Locker Slot",
        element: <NewLockerSlot />,
        path: "lockers/slots/new/:id",
        notOnList: true,
    },
    {
        name: "Edit Locker Slot",
        element: <EditLockerSlot />,
        path: "lockers/slots/edit/:id",
        notOnList: true,
    },
    {
        name: "Audit Logs",
        element: <AuditLog />,
        path: "logs",
        icon: <FaRegListAlt size={"1.25em"} />,
    },
    {
        name: "Audit Logs",
        element: <AuditLogLocker />,
        path: "lockers/logs/:id",
        notOnList: true
    }
];
