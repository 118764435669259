export default function FormInput(props) {
    return (
        <div className="mb-4 w-full">
            <label
                htmlFor={props.id}
                className="form-label inline-block mb-2 text-gray-800 text-md"
            >
                {props.name}
            </label>
            {props.type != "textarea" && (
                <input
                    type={props.type ? props.type : "text"}
                    className="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                    id={props.id}
                    defaultValue={props.defaultValue}
                    min={props.min}
                    disabled={props.disabled}
                    {...props.register(props.id)}
                />
            )}
            {props.type == "textarea" && (
                <textarea
                    className="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                    rows={props.rows}
                    id={props.id}
                    disabled={props.disabled}
                    {...props.register(props.id)}
                >
                    {props.defaultValue}
                </textarea>
            )}

            {props.errors[props.id] && (
                <div className="text-red-500">
                    {props.errors[props.id]?.message}
                </div>
            )}
        </div>
    );
}
