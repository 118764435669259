import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/userContext";

export default function ApiHook() {
    const [loading, setLoading] = useState(false);
    const backend_url = "https://locker.epitome.com.sg";
    
    const request = (endpoint, method, data = null) => {
        return new Promise(async (resolve, reject) => {
            setLoading(true);
            await axios({
                method: method,
                url: `${backend_url}${endpoint}`,
                data: data,
            })
                .then((response) => {
                    setLoading(false);
                    resolve(response.data);
                })
                .catch((err) => {
                    setLoading(false);
                    reject(err.response.data);
                });
        });
    };

    const authRequest = (endpoint, method, data = null, token) => {
        return new Promise(async (resolve, reject) => {
            setLoading(true);
            await axios({
                method: method,
                url: `${backend_url}${endpoint}`,
                data: data,
                headers: {
                    "auth-token": token,
                },
            })
                .then((response) => {
                    setLoading(false);
                    resolve(response.data);
                })
                .catch((err) => {
                    setLoading(false);
                    reject(err.response.data);
                });
        });
    };

    const login = (username, password) => {
        return request("/login/admin", "POST", {
            username: username,
            password: password,
        });
    };

    const getLockers = (token) => {
        return authRequest("/admin/locker", "GET", null, token);
    };

    const getLocker = (token, id) => {
        return authRequest(`/admin/locker/${id}`, "GET", null, token);
    };

    const createLocker = (token, data) => {
        return authRequest("/admin/locker/new", "POST", data, token);
    };

    const updateLocker = (token, id, data) => {
        return authRequest(`/admin/locker/${id}`, "PUT", data, token);
    };

    const deleteLocker = (token, id) => {
        return authRequest(`/admin/locker/delete/${id}`, "DELETE", null, token);
    };

    const deleteLockerSlot = (token, id) => {
        return authRequest(`/admin/slot/delete/${id}`, "DELETE", null, token);
    };

    const getLockerSlotsByLocker = (token, id) => {
        return authRequest(`/admin/locker/slot/${id}`, "GET", null, token);
    };

    const getAvailableLockerSlotsByLocker = (token, id) => {
        return authRequest(`/admin/locker/availableslot/${id}`, "GET", null, token);
    };

    const getLockerRequests = (token) => {
        return authRequest("/admin/request", "GET", null, token);
    };

    const getAuditLogs = (token) => {
        return authRequest("/admin/getAuditLogs", "GET", null, token);
    }

    const getAuditLogsByLocker = (token, id) => {
        return authRequest(`/admin/locker/getAuditLogs/${id}`, "GET", null, token);
    }

    const deleteLockerRequest = (token, id) => {
        return authRequest(`/admin/request/${id}`, "DELETE", null, token);
    };
    const createRequest = (token, data) => {
        return authRequest("/admin/request", "POST", data, token);
    };

    const updateRequest = (token, id, data) => {
        return authRequest(`/admin/request/${id}`, "PUT", data, token);
    };
    const getRequest = (token, id) => {
        return authRequest(`/admin/request/${id}`, "GET", null, token);
    };

    const createLockerSlot = (token, data) => {
        return authRequest("/admin/slot", "POST", data, token);
    };

    const getLockerSlotsById = (token, id) => {
        return authRequest(`/admin/slot/${id}`, "GET", null, token);
    };

    const updateLockerSlot = (token, id, data) => {
        return authRequest(`/admin/slot/${id}`, "PUT", data, token);
    };

    const openLockerSlot = (token, id) => {
        return authRequest(`/admin/slot/open/${id}`, "GET", null, token);
    };

    const faAuth = (code, id) => {
        return request("/login/2fa", "POST", {
            code: code,
            id: id,
        });
    };

    return {
        login,
        getLockers,
        createLocker,
        createLockerSlot,
        updateLocker,
        deleteLocker,
        getLockerSlotsByLocker,
        deleteLockerSlot,
        getLocker,
        loading,
        getLockerRequests,
        deleteLockerRequest,
        createRequest,
        updateRequest,
        getRequest,
        openLockerSlot,
        getLockerSlotsById,
        updateLockerSlot,
        getAuditLogs,
        getAuditLogsByLocker,
        getAvailableLockerSlotsByLocker,
        faAuth
    };
}
